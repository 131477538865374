import React, { useContext } from "react" ;
import { UserContext } from "./providers/userProvider2" ;
import LightStreamData from "./components/LighstreamData";

export default (props) => {    
    const user = useContext(UserContext) ;
    console.log("Logged user:",user) ;
    if (!user) {
         return (
             <div>User is null ??</div>
         )
    }
    //const email = user.email ;
    const displayName = user.displayName ;
    
    return (
        <div className="homepage">
            Edit the greenroom link here
            <LightStreamData/>
        </div>
        
    );
}