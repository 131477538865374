import React, { useContext } from "react" ;
//import InstallationsList from './components/InstallationsList' ;
//import Installations from "./Installations";


// css..

import { UserContext } from "./providers/userProvider2" ;

export default (props) => {    
    const user = useContext(UserContext) ;
    console.log("home:" + user) ;
    if (!user) {
         return (
             <div>User is null ??</div>
         )
    }
    //const email = user.email ;
    const displayName = user.displayName ;
    
    return (
        <div className="homepage">
            Home page / HOME
        </div>
    );
}