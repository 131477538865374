import React from "react" ;
import GreenRoomTarget from "./components/GreenRoomTarget";

export default (props) => {    
    
    return (
        <div className="homepage">
            <GreenRoomTarget/>
        </div>
        
    );
}