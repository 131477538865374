import React, { useContext } from "react" ;
import { Redirect } from 'react-router-dom' ;

// css..
import { UserContext } from "./providers/userProvider2" ;
import { signInWithGoogle } from "./services/firebase" ;
export default function Login () {
    const user = useContext(UserContext) ;
    console.log(user) ;
    //let email = 'no email' ;
    let displayName = 'no name' ;
    if (user) {
        //email = user.email ;
        displayName = user.displayName ;
    }
    if (user) {
        console.log("redirect to /manage") ;
        return (
            <Redirect to={{ pathname: '/manage' }} />
        )
    }
    return (
        <div>
            <button className="button" onClick={signInWithGoogle}><i className="fab fa-google"></i>Sign in with google</button>
        </div>
    );
}