import  firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import dotenv from 'dotenv'

dotenv.config()

if (!firebase.apps.length) {
  console.log("initialiaze fb:",process.env.REACT_APP_CLIENT_ID  );
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId:  process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  });
}
export const auth = firebase.auth();
export const database = firebase.database() ;

export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).then((res) => {
    console.log(res.user)
  }).catch((error) => {
    console.log(error.message)
  })
}

export const logOut = () => {
  auth.signOut().then(() => {
    console.log('logged out!');
  }).catch((error) => {
    console.log("error occured at logout:" + error.message)
  })
}

export function sanitizeFirebaseKey(str){
  str = str.replace(/\.|#|\$|\/|\[|\]/g,'_');
  return str.trim();
}