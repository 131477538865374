import React, { useEffect, useState } from 'react';
import GreenRoomLink from "../services/lightstreamlink" ;
import { Button } from '@material-ui/core' ;

export default function GreenRoomTarget() {
    const [ lightStreamData, setLighStreamData ] = useState(undefined) ;

    useEffect( () => {
        GreenRoomLink.getAll().on("value", onDataChange) ;
        return () => {
            GreenRoomLink.getAll().off("value");
        }
    }, []);

    const onDataChange = async (e) => {
        console.log("onDataChange", e.val()) ;
        setLighStreamData(e.val().value);
    }

    if (! lightStreamData) {
        return (
            <>Loading</>
        )
    }
    return (
        <div>
            <Button variant="contained" size="large" href={lightStreamData} target="_blank">Wengo Live</Button>
        </div>
    )
}