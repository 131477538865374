import React from 'react';
import { BrowserRouter , Switch, Route, Link, useHistory } from "react-router-dom" ;
import { Button } from '@material-ui/core' ;
import './App.css';
//import NavBar from './NavBar' ;
import Login from './Login' ;
import Home from './Home' ;
import UserProvider from './providers/userProvider2' ;
import Manage from './Manage' ;
import ToGreenRoom from './ToGreenRoom' ;
import ProtectedRoute from './components/ProtectedRoute' ;


function App() {
  return (
    <>
    <div className="App">
      <ToGreenRoom/>
      <UserProvider>       
        <BrowserRouter>                    
          
          <Link to="/manage">manage</Link>
          <Switch>
            <Route path="/login"><Login /></Route>            
            <ProtectedRoute path="/manage" component={Manage}/>
          </Switch>
        </BrowserRouter>        
      </UserProvider>
    </div>
    </>
  );
}

export default App;