import { database,sanitizeFirebaseKey  } from "../services/firebase" ;

const currentGreenRoomLink = database.ref("/greenRoomLink") ;

class GreenRoomLink {
    getAll() {
        return currentGreenRoomLink ;
    }
}

export default new GreenRoomLink();
