import React, { useEffect, useState } from 'react';
import GreenRoomLink from "../services/lightstreamlink" ;


export default function LightStreamData() {
    const [ lightStreamData, setLighStreamData ] = useState(undefined) ;

    useEffect( () => {
        GreenRoomLink.getAll().on("value", onDataChange) ;
        return () => {
            GreenRoomLink.getAll().off("value");
        }
    }, []);

    const onDataChange = async (e) => {
        console.log("onDataChange", e.val()) ;
        setLighStreamData(e.val().value);
    }

    const updateGreenRoomLink = (event) => {
            GreenRoomLink.getAll().update({ value: event.target.value });        
    }

    if (! lightStreamData) {
        return (
            <>Loading</>
        )
    }

    return (
        <div>
            <input type="text" value={lightStreamData} onChange={updateGreenRoomLink}/>
        </div>
    )
}