import React, { useContext } from 'react' ;
import { Redirect } from 'react-router-dom' ;
import { UserContext } from "../providers/userProvider2" ;


const ProtectedRoute = (props) =>   {
    const user = useContext(UserContext) ;
    console.log("props=" + props)
    const Component = props.component;
    if (user) {
        console.log("User is logged");
        return (
                <Component />
        )
    } else {
        console.log("User is not logged");
        return (
            <Redirect to={{ pathname: '/login' }} />
        )
    }
    
};


export default ProtectedRoute ;